<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>审批管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="tabs-group">
            <Tabs @on-click="handleTabClick">
                <TabPane label="领用审批" name="receiving">
                    <Process :tabName="tabName" v-if="tabName === 'receiving'"></Process>
                </TabPane>
                <TabPane label="派发审批" name="distribute">
                    <Process :tabName="tabName" v-if="tabName === 'distribute'"></Process>
                </TabPane>
                <TabPane label="调拨审批" name="allocate">
                    <Process :tabName="tabName" v-if="tabName === 'allocate'"></Process>
                </TabPane>
                <TabPane label="退库审批" name="returning">
                    <Process :tabName="tabName" v-if="tabName === 'returning'"></Process>
                </TabPane>
                <TabPane label="借用审批" name="borrow">
                    <Process :tabName="tabName" v-if="tabName === 'borrow'"></Process>
                </TabPane>
                <TabPane label="归还审批" name="giveBack">
                    <Process :tabName="tabName" v-if="tabName === 'giveBack'"></Process>
                </TabPane>
                <TabPane label="维修审批" name="repair">
                    <Process :tabName="tabName" v-if="tabName === 'repair'"></Process>
                </TabPane>
                <TabPane label="保养审批" name="maintain">
                    <Process :tabName="tabName" v-if="tabName === 'maintain'"></Process>
                </TabPane>
                <TabPane label="处置审批" name="dispose">
                    <Process :tabName="tabName" v-if="tabName === 'dispose'"></Process>
                </TabPane>
<!--                <TabPane label="盘点审批" name="inventory">-->
<!--                    <Process :tabName="tabName" v-if="tabName === 'inventory'"></Process>-->
<!--                </TabPane>-->
            </Tabs>
        </div>
    </div>
</template>
<script>
import Process from './process.vue';

export default {
    components:{
        Process
    },
    data() {
        return {
            tabName: 'receiving'
        }
    },
    methods: {
        handleTabClick(name) {
            this.tabName = name;
        }
    },
    mounted() {
    }
}
</script>
