<template>
    <div class="tabs-content">
        <div class="switch">
            <i-switch size="large" v-model="myProcess.isEnabled" @on-change="handleStatusChange">
                <span slot="open">启用</span>
                <span slot="close">关闭</span>
            </i-switch>
        </div>
        <div class="flow-chart">
            <div class="flow-start">
                <div class="flow-start-text">{{typeArray[tabName].starterTitle}}</div>
                <div class="flow-start-dot"></div>
            </div>
            <div class="flow-connect"></div>
            <div v-for="(item, index) in myProcess.stepList" :key="index">
                <div class="flow-step">
                    <Card>
                        <div slot="title">{{item.name}}</div>
                        <div class="flow-people" v-if="item.isEditable === 1">
                            <Tag size="large" closable v-for="(obj, idx) in item.handlerList" :key="idx" @on-close="handleRemove(item.id, obj.id)">
                                {{obj.name}}
                            </Tag>
                            <br/>
                            <Button type="primary" shape="circle" icon="md-add"
                                    @click="handleEditApprover(item.name, item.id)">
                            </Button>
                        </div>
                    </Card>
                </div>
                <div class="flow-connect"></div>
            </div>
            <div class="flow-end">
                <div class="flow-end-dot"></div>
                <div class="flow-end-text">流程结束</div>
            </div>
        </div>
        <div class="footer-btn">
            <Button type="primary" :loading="savingLoading" @click="handleEditing">保存</Button>
        </div>

        <EditApprover
            v-model="editShow"
            :myTitle="myTitle"
            :myPsId="psId"
            :myApproverIds="approverIds"
            :myRoleIds="roleIds"
            :tabName="tabName"
            v-on:setStepApprover="setStepApprover">
        </EditApprover>
    </div>
</template>

<script>
import {reqGetProcessByType, reqEditProcess, reqToggleProcessStatus} from '@/api/asset-api'
import EditApprover from './edit';

export default {
    name: "process",
    props: {
        tabName: ''
    },
    components:{
        EditApprover
    },
    data() {
        return {
            processType: '',
            myProcess: {
                id: '',
                isEnabled: null,
                stepList: []
            },
            typeArray: {
                'receiving': {flowTitle: '领用审批流程', starterTitle: '领用人发起审批'},
                'distribute': {flowTitle: '派发审批流程', starterTitle: '发起审批'},
                'allocate': {flowTitle: '调拨审批流程', starterTitle: '经办人发起审批'},
                'returning': {flowTitle: '退库审批流程', starterTitle: '发起审批'},
                'borrow': {flowTitle: '借用审批流程', starterTitle: '发起审批'},
                'giveBack': {flowTitle: '归还审批流程', starterTitle: '发起审批'},
                'repair': {flowTitle: '维修审批流程', starterTitle: '发起审批'},
                'maintain': {flowTitle: '保养审批流程', starterTitle: '发起审批'},
                'dispose': {flowTitle: '处置审批流程', starterTitle: '发起审批'},
                'inventory': {flowTitle: '盘点审批流程', starterTitle: '发起审批'}
            },
            editShow: false,
            savingLoading: false,
            myTitle: '',
            psId: '',
            approverIds: [],
            roleIds: []
        }
    },
    methods: {
        handleStatusChange() {
            let invalidData = this.myProcess.stepList.find(item => item.isEditable === 1 && (!item.handlerList || item.handlerList.length === 0));
            if (invalidData) {
                this.$Message.error('请设置流程审批人');
                return;
            }

            let msg = this.typeArray[this.tabName].flowTitle;
            if (this.myProcess.isEnabled) {
                msg += '启用';
            } else {
                msg += '关闭';
            }
            reqToggleProcessStatus(this.myProcess).then((res)=> {
                if (res.data.code === 1) {
                    this.$Message.success(msg + '成功');
                } else {
                    this.$Message.error(msg + '失败');
                }
            }).catch(()=>{
                this.$Message.error(msg + '失败');
            })
        },
        getProcessByType() {
            reqGetProcessByType({type: this.tabName}).then(res => {
                this.myProcess = res.data.data;
            }).catch(()=>{
                this.myProcess = {};
            });
        },
        handleRemove(psId, itemId) {
            let step = this.myProcess.stepList.find(item => item.id === psId);
            let myHandler = step.handlerList.filter(obj => obj.id !== itemId);
            step.handlerList = myHandler;
        },
        handleEditApprover(title, id) {
            this.myTitle = title;
            this.psId = id;
            this.editShow = true;
            this.approverIds = this.myProcess.stepList.find(item => item.id === id).handlerList.filter(obj => obj.type === 'sys_user').map(item => item.id);
            this.roleIds = this.myProcess.stepList.find(item => item.id === id).handlerList.filter(obj => obj.type === 'sys_role').map(item => Number(item.id));
        },
        setStepApprover(psId, approverArray, roleArray) {
            let myHandlerList = [];
            roleArray?.map(item => {
                myHandlerList.push({id: item.value, name: item.label, type: 'sys_role'})
            });
            if(approverArray.length > 0){
                approverArray?.map(item => {
                    myHandlerList.push({id: item.value, name: item.label, type: 'sys_user'})
                });
            }else{
                myHandlerList.push({id: approverArray.value, name: approverArray.label, type: 'sys_user'})
            }
            this.myProcess.stepList.find(item => item.id === psId).handlerList = myHandlerList;
        },
        handleEditing() {
            let invalidData = this.myProcess.stepList.find(item => item.isEditable === 1 && (!item.handlerList || item.handlerList.length === 0));
            if (invalidData) {
                this.$Message.error('请选择审批人');
                return;
            }

            this.savingLoading = true;
            reqEditProcess(this.myProcess).then((res)=> {
                this.savingLoading = false;
                if (res.data.code === 1) {
                    this.$Message.success('编辑成功');
                } else if (res.data.code === -1) {
                    this.$Message.error('未设置审批流程');
                } else if (res.data.code === -2) {
                    this.$Message.error('未设置审批人');
                } else {
                    this.$Message.error('编辑失败');
                }
            }).catch(()=>{
                this.savingLoading = false;
                this.$Message.error('编辑失败');
            })
        }
    },
    mounted() {
        this.getProcessByType();
    }
}
</script>
