<template>
    <Modal v-model="showForm" :title="myTitle" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="90">
            <FormItem label="审批人" prop="approverIds">
                <Select v-model="editForm.approverIds" placeholder="请选择" label-in-value filterable clearable @on-change="handleApproverChange" v-if="this.tabName === 'allocate' || this.tabName === 'receiving'">
                    <Option v-for="item in userList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
                <Select v-model="editForm.approverIds" placeholder="请选择" label-in-value multiple filterable clearable @on-change="handleApproverChange" v-else>
                    <Option v-for="item in userList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="审批角色" prop="roleIds" v-if="this.tabName !== 'allocate' && this.tabName !== 'receiving'">
                <Select v-model="editForm.roleIds" placeholder="请选择" label-in-value multiple filterable clearable @on-change="handleRoleChange">
                    <Option v-for="item in roleList" :value="item.roleId" :key="item.roleId">{{ item.roleName }}</Option>
                </Select>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>

<script>
import {reqGetRoleList, reqGetUsers, reqGetUserList} from '../../api/system-api'

export default {
    name: "editApprover",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        tabName: '',
        myTitle: '',
        myPsId: '',
        myApproverIds: [],
        myRoleIds: []
    },
    data() {
        return {
            showForm: false,
            loading: false,
            userList: [],
            roleList: [],
            approverArray: [],
            roleArray: [],
            editForm: {
                psId: '',
                approverIds: [],
                roleIds: []
            },
            editFormRule: {
            }
        }
    },
    methods: {
        getRoleList() {
            reqGetRoleList().then(res => {
                this.roleList = res.data;
            }).catch(() => {
                this.roleList = [];
            });
        },
        getUserList() {
            reqGetUserList().then(res => {
                this.userList = res.data;
            }).catch(() => {
                this.userList = [];
            });
        },
        getUsers() {
            reqGetUsers().then(res => {
                this.userList = res.data;
            }).catch(() => {
                this.userList = [];
            });
        },
        handleApproverChange(value) {
            this.approverArray = value;
        },
        handleRoleChange(value) {
            this.roleArray = value;
        },
        closeModal(val) {
            this.$emit('input', val);
        },
        handleSubmit() {
            this.closeModal(false);
            this.$emit('setStepApprover', this.editForm.psId, this.approverArray, this.roleArray);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.editForm.psId = this.myPsId;
                this.editForm.approverIds = this.myApproverIds;
                this.editForm.roleIds = this.myRoleIds;
                this.getRoleList();
                if(this.tabName === "allocate" || this.tabName === "receiving"){
                    this.getUsers();
                }else{
                    this.getUserList();
                }
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
